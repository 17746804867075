import React, { useEffect, useRef, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import EventIcon from '@mui/icons-material/Event';
import Input from '../Input';
import 'dayjs/locale/es';


export default function DatePickerCalendar({
  value, // Nuevo: Recibe el valor del formulario
  minDate,
  onChange, //
  maxDate,
  inputProps,
  ...props
}) {
  const [show, setShow] = useState(false);
  const [labelValue, setLabelValue] = useState('Seleccione una fecha');
  const handleDateChange = (newValue) => onChange?.(newValue);
  dayjs.locale('es');
  const ref = useRef(null); // Referencia al contenedor del calendario
  // Detectar clic fuera del calendario
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false); // Cierra el calendario
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    try {
      const dateLabel = dayjs(value); // Crear instancia de dayjs con el valor
      if (!value) {
        setLabelValue('Seleccione una fecha');
        return
      }
      setLabelValue(
        dateLabel.isValid()
          ? dateLabel.format('MM/DD/YYYY')
          : 'Seleccione una fecha'
      );
    } catch (error) {
      setLabelValue('Seleccione una fecha');
    }
  }, [value]);

  return (
    <div className='relative w-full' ref={ref}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
        <Input
          readOnly
          {...inputProps}
          value={labelValue}
          cursorPointer
          onClick={() => setShow(!show)}
          endAdornment={<EventIcon className='text-slate-500' />}
        />

        {
          <StaticDatePicker
            {...props}
            dis
            views={['year', 'month', 'day']}
            onChange={handleDateChange}
            displayStaticWrapperAs='desktop'
            onAccept={() => setShow(false)}
            closeOnSelect={true}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            className={`${
              show
                ? 'opacity-100 scale-100 translate-x-0'
                : ' invisible translate-y-5 opacity-0 scale-95'
            } 
           absolute top-[3.2rem] right-0 w-full md:w-2/3 z-20 transform transition-all duration-200 ease-in-out rounded-xl shadow-md bg-white
           overflow-hidden`}
          />
        }
      </LocalizationProvider>
    </div>
  );
}
