import * as React from 'react';
import CreateContinueApp from '../components/CreateContinueApp';
import Slider from '../components/Slider';
import { useState } from 'react';
import '../../../Shared/css/animations.css'; // A
import Register from '../components/Register';
import { useEffect } from 'react';

const UserCreateAccount = () => {
  const [showOptions, setShowOptions] = useState('options');

  useEffect(() => {
   
    if (sessionStorage.getItem('rgxs')  && sessionStorage.getItem('rgxs')  == '1') {
      setShowOptions('register')
    }
  
  }, [])

  const goBack = () => {
    setShowOptions('options');
    sessionStorage.removeItem('rgxs');
  }
  

  return (
    <section className='h-full overflow-x-hidden w-full flex  flex-col content-center items-center justify-center bg-[#EDEEF8]'>
      {showOptions == 'options' && (
        <div
          className={` 
            animate-fadeIn 
            flex flex-col w-full md:w-[34rem] h-screen gap-8 md:gap-6`}
        >
          <section className='h-3/5 md:h-2/4 w-full'>
            <Slider />
          </section>
          <CreateContinueApp onSelected={setShowOptions} />
        </div>
      )}
      {showOptions != 'options' && (
        <div
          className='px-12 pt-10
          animate-fadeIn
          w-full h-full
          flex
          justify-center
          md:content-start
          '
        >
          <Register
            type={showOptions}
            handleGoBack={goBack}
          />
        </div>
      )}
    </section>
  );
};

export default UserCreateAccount;
