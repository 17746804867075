import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
import { CircularProgress, FormControl } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { selectApplications } from '../store/applications';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import GenericModal from './GenericModal';
import { NumericFormatCustom } from '../Shared/NumericFormatCustom';
import { useAuth } from '../hooks/useAuth';
import { getPlanCoutes } from '../api/application';
import { currencyFormat } from '../helpers/formaHelper';
import { MIN_AMOUNT_VALUE, MIN_FEE_AMOUNT } from '../core/enums/credit';
import Modal from './common/Modal/Modal';
import AppRiskInProccess from '../Shared/AppRiskInProccess';
import RejectedApp from '../Shared/RejectedApp';
import AppInProcess from '../Shared/AppInProcess';
import Btn from '../Components/common/Btn';
import Input from './common/Input';
import { handleNext } from '../store/applicationStep';
import store from '../store';
import { APPROVED, SIGN_CONTRACT } from '../core/enums/appStatus';

export default function Offer() {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  const [cuotas, setCuotas] = useState([]);
  const [cuota, setCuota] = useState(0);
  const [montoInicial, setMontoInicial] = useState(0);
  const [montoMax, setMontoMax] = useState(0);
  const [estado, setEstado] = useState('');
  const [showRiskInProcess, setShowRiskInProcess] = useState(false);
  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [showOnHoldModal, setShowOnHoldModal] = useState(false);
  const [showInProcess, setShowInProcess] = useState(false);
  const [idApplication, setIdApplication] = useState(false);
  const [fetchingOptions, setFetchingOptions] = useState('idle');
  useSelector(selectApplications);
  const { logOut } = useAuth();

  const TRIES_TO_APP = 6;
  const getOptions = async (appId) => {
    try {
      setFetchingOptions('pending');
      setCuotas([]);
      setCuota(0);
      const { data } = await getPlanCoutes(appId);
      orderCuotas(data.data);
      setFetchingOptions('succeded');
    } catch (error) {
      setFetchingOptions('rejected');
    }
  };

  useEffect(() => {
    const getApplications = (tries = 0) => {
      const profileId = sessionStorage.getItem('profile_id');
      axios
        .get(
          `${process.env.REACT_APP_WELLI_API}/api/applications/risk/${profileId}`
        )
        .then((resp) => {
          const estadoDb =
            resp?.data?.risk?.estado || resp?.data?.app?.estado || null;
          
          if (estadoDb === APPROVED || estadoDb == SIGN_CONTRACT) {
            if (!montoInicial) {
              const {monto, monto_maximo_credito} = resp.data.risk
              setMontoInicial(monto);
              setMontoMax(monto_maximo_credito);
            }
            setEstado(estadoDb);
            setIdApplication(resp.data.app.id);

            getOptions(resp.data.app.id);
          } else if (estadoDb === 'rejected') {
            showModals('rejected');
          } else if (estadoDb === 'on_hold_docs') {
            showModals('on_hold_docs');
          } else if (estadoDb === 'risk_in_process' && tries < TRIES_TO_APP) {
            showModals('in_process');
            setTimeout(() => {
              getApplications(tries + 1);
            }, 10000);
          } else if (estadoDb === 'risk_in_process' && tries == TRIES_TO_APP) {
            showModals('risk_in_process');
          } else {
            showModals('in_process');
          }
        })
        .catch(() => {
          showModals('rejected');
        });
    };

    getApplications();
  }, [setValue]);

  const showModals = (type) => {
    setShowRejectedModal(false);
    setShowInProcess(false);
    setShowOnHoldModal(false);
    setShowRiskInProcess(false);
    switch (type) {
      case 'rejected':
        setShowRejectedModal(true);
        break;
      case 'on_hold_docs':
        setShowOnHoldModal(true);
        break;
      case 'risk_in_process':
        showRiskInProcess(true);

        break;
      case 'in_process':
        setShowInProcess(true);
        break;
    }
  };
  const updateNewPaymentPlan = (handleGetOptions = false) => {
    setFetchingOptions('pending');
    axios
      .patch(
        `${process.env.REACT_APP_WELLI_API}/api/application/${idApplication}`,
        {
          monto: parseInt(getValues().monto_inicial),
          cuotas: parseInt(cuota),
        }
      )
      .then(() => {
        if (handleGetOptions) {
          getOptions(idApplication);
        } else {
          store.dispatch(handleNext(1));
        }
      })
      .catch(() => {
        setFetchingOptions('rejected');
      });
  };

  const orderCuotas = (dbCuotas) => {
    const dbCoutasEntries = Object.entries(dbCuotas).sort(
      (a, b) => b[0] - a[0]
    );
    const newCoutas = dbCoutasEntries
      .filter((x) => x[1].resultado == true)
      .map((c) => ({ key: c[0], value: c[1]['cuota_asignacion'] }))
      .filter((x) => x.value >= MIN_FEE_AMOUNT);
    if (newCoutas.length) {
      setCuotas(newCoutas);
      setCuota(newCoutas[0].key);
    }
  };

  const notValidatedApplication = () => {
    logOut();
  };

  return (
    <section className='  w-full flex  flex-col content-center items-center justify-center '>
      <Modal showClose={false} open={showRiskInProcess}>
        <AppRiskInProccess />
      </Modal>
      <Modal open={showRejectedModal}>
        <RejectedApp />
      </Modal>
      <Modal open={showInProcess}>
        <AppInProcess />
      </Modal>

      <GenericModal
        show={showOnHoldModal}
        title='Felicitaciones! Tu crédito ha sido pre-aprobado. '
        bodyComponent={
          <>
            <Typography
              variant='body1'
              color='text.secondary'
              align='left'
              gutterBottom
              sx={{ marginBottom: '20px' }}
            >
              Estamos a un paso de poder finalizar el proceso y que puedas tomar
              tu crédito. Sin embargo, antes de eso, necesitamos que nos envíes
              algunos documentos adicionales.
            </Typography>

            <Typography
              variant='body1'
              color='text.secondary'
              align='left'
              gutterBottom
              sx={{ marginBottom: '20px' }}
            >
              Si eres <strong>empleado</strong> , por favor envíanos tus últimos
              3 desprendibles de nómina y la carta con certificación laboral a
              la fecha. <br />
            </Typography>
            <Typography
              variant='body1'
              color='text.secondary'
              align='left'
              gutterBottom
              sx={{ marginBottom: '20px' }}
            >
              Si eres <strong>independiente</strong>, por favor compártenos tus
              últimos 3 extractos bancarios y tu declaración de renta. <br />
            </Typography>
            <Typography
              variant='body1'
              color='text.secondary'
              align='left'
              gutterBottom
              sx={{ marginBottom: '20px' }}
            >
              Si necesitas asesoría adicional <br />
              escribenos a servicioalcliente@welli.com.co ó al Whatsapp +57
              310-5240287.
            </Typography>
          </>
        }
        confirm={notValidatedApplication}
      ></GenericModal>

      <section className=' w-full flex  flex-col  items-center px-6 py-8  md:px-12 md:pt-10 md:w-[34rem] '>
        {estado === 'approved' ? (
          <>
            <h3 className='font-bold text-2xl md:text-3xl text-center'>¡Felicitaciones!</h3>
            <img
              src='assets/img/Personaje_Welli_posturas-15.png'
              alt='Welli postura'
              className=' w-36 md:w-40'
            />

            <p className=' text-center text-lg font-semibold px-4'>
              Tienes un crédito aprobado con Welli por un monto de hasta
            </p>

            <div className='flex flex-col items-center justify-center mt-2'>
              <h3 className='font-bold text-3xl text-center'>
                ${currencyFormat(montoMax)}
              </h3>
            </div>
            <form
              className='mt-6 w-full'
              onSubmit={handleSubmit(() => updateNewPaymentPlan(true))}
            >
              <p className=' text-slate-800  text-center text-lg mb-2'>
                Ingresa el monto que deseas tomar
              </p>

              <section className='flex justify-between content-between gap-4'>
                <Controller
                  name='monto_inicial'
                  control={control}
                  defaultValue={montoInicial}
                  fullWidth
                  rules={{
                    required: true,
                    min: MIN_AMOUNT_VALUE,
                    max: montoMax,
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      isCurrency
                      placeholder='¿Cuánto dinero necesitas?'
                      value={montoInicial}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      onChange={(e) => {
                        const { value } = e.target;
                        setValue('monto_inicial', value, {
                          shouldValidate: true,
                        });
                      }}
                      error={Boolean(errors.monto_inicial)}
                    />
                  )}
                  error={Boolean(errors.monto_inicial)}
                  helperText={errors.monto_inicial?.message}
                />

                <Btn type='submit' color='secondary'>
                  Actualizar
                </Btn>
              </section>
              <section className='mt-2'>
                {errors.monto_inicial && (
                  <Typography variant='caption' color='error'>
                    {errors.monto_inicial.type === 'required' &&
                      'El monto a solicitar es requerido'}
                    {errors.monto_inicial.type === 'max' &&
                      `El monto a solicitar no puede superar los ${currencyFormat(
                        montoMax
                      )} `}
                    {errors.monto_inicial.type === 'min' &&
                      `El monto a solicitar no puede ser inferior a los a $ ${currencyFormat(
                        MIN_AMOUNT_VALUE
                      )} `}
                  </Typography>
                )}
              </section>
            </form>
            {fetchingOptions == 'pending' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginY: '70px',
                }}
              >
                <Typography
                  variant='h6'
                  color='primary'
                  sx={{ marginBottom: '20px' }}
                >
                  Estamos recalculando las cuotas...
                </Typography>
                <CircularProgress />
              </Box>
            )}
            {fetchingOptions == 'rejected' && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginY: '70px',
                }}
              >
                <Typography
                  variant='h6'
                  color='danger'
                  sx={{ marginBottom: '20px' }}
                >
                 Ha ocurrido un error
                </Typography>
                <CircularProgress />
              </Box>
            )}
            {fetchingOptions == 'succeded' && (
              <>
                <Container
                  disableGutters
                  maxWidth='sm'
                  component='main'
                  sx={{ pt: 1, pb: 1 }}
                >
                  <p className=' text-center text-slate-800 text-lg mt-4 mb-1'>
                    Selecciona tu plan de cuotas fijas
                  </p>
                </Container>
                <section className='w-full md:ml-4'>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={cuota}
                      onChange={(e) => setCuota(e.target.value)}
                    >
                      {cuotas.map((cuota) => (
                        <FormControlLabel
                          value={cuota.key}
                          control={<Radio />}
                          label={<>
                          
                          <p className=' text-slate-800 text-base'>
                            {
                            cuota.key
                          } cuotas mensuales de: ${currencyFormat(
                            cuota.value,
                            false
                          )}
                          </p>
                          </>}
                          key={cuota.key}
                          sx={{ wordSpacing: '8px', mb:'3px' }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </section>

              </>
            )}
            {fetchingOptions == 'succeded' && cuota != 0 && (
              <Btn
                type='button'
                onClick={handleSubmit(() => updateNewPaymentPlan(false))}
                color='secondary'
                className=' w-full mb-4 mt-6 '
              >
                <span className='w-[90%]'>Siguiente </span>
                <KeyboardBackspaceIcon
                  sx={{ fontSize: 30 }}
                  className='right-3 rotate-180 '
                />
              </Btn>
            )}
            {fetchingOptions == 'succeded' && cuotas.length == 0 && (
              <Typography
                variant='body1'
                align='center'
                color='primary'
                component='p'
              >
                No se encontraron cuotas disponibles
              </Typography>
            )}
          </>
        ) : (
          <Typography
            variant='body1'
            align='center'
            color='primary'
            component='p'
          >
            Estamos verificando el estado de tu solicitud...
          </Typography>
        )}
      </section>
    </section>
  );
}
