import React from 'react';
import Button from '../../../Components/common/Btn';
import { useAuth } from '../../../hooks/useAuth';

const CreateContinueApp = ({ onSelected }) => {
  const { logOut } = useAuth();

  return (
    <section className='px-6 py-2 text-center gap-6 flex flex-col'>
      <Button
        onClick={() => onSelected('new')}
        size='medium'
        color='white'
        className='w-full '
      >
        Solicitar un crédito
      </Button>
      <Button
        variant='outlined'
        size='medium'
        color='white'
        className='w-full bg-transparent'
        onClick={() => onSelected('continue')}
      >
        Retomar mi solicitud
      </Button>
      <div>
        <p
          onClick={logOut}
          className=' text-slate-900 cursor-pointer text-center underline underline-offset-4'
        >
          Ingresar a mi cuenta
        </p>
      </div>
    </section>
  );
};

export default CreateContinueApp;
