import { AppBar } from '@mui/material';
import React from 'react';
import Btn from '../Components/common/Btn';
import LogoutIcon from '@mui/icons-material/Logout';

import { useAuth } from '../hooks/useAuth';

const LayoutCreateAccount = ({ children }) => {
  const { logOut } = useAuth();
  const logout = () => {
    logOut();
  };
  return (
    <div className='h-full overflow-y-auto bg-[#EDEEF8]'>
      <div>
        <AppBar
          position='static'
          style={{ boxShadow: 'none', border:'none', padding: ' .5rem .8rem', background:'#EDEEF8' }}
        >
          <Btn onClick={logout} color='danger' size='small' className='self-end w-36 mt-2'>
            <LogoutIcon/>
            Salir
          </Btn>
        </AppBar>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default LayoutCreateAccount;
